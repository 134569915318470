@import "../../styles/variables.scss";

.campaign-list {
  .no-records {
    display: flex;
    align-items: center;
    width: 100%;
    height: 200px;
    justify-content: center;
    font-weight: 700;
    color: grey;
    font-size: 20px;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(91.5vh - 13.6rem);
  }

  .empty-state {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(91.5vh - 13.6rem);
    img {
      width: 14.125rem;
      height: 14.8125rem;
    }
    p {
      font-size: 2rem;
      font-weight: 700;
      color: rgb(160, 160, 160);
    }
  }
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;

  .filter-container-search {
    width: 30%;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 12px;

    .input-item:hover:not(:focus, :active, :focus-within) {
      border: none;
      border-bottom: 1px solid #3a3a3a;
    }
  }
  .filter-container-popover {
    width: 85%;
    .vertical-layout {
      display: block;
      .horizontal-layoutItem-0 {
        .dropdown-container {
          height: 150px;
          width: 190px;
          z-index: 100;
        }
      }
      .horizontal-layoutItem-1 {
        .dropdown-container {
          width: 320px;
          height: 230px;
          z-index: 100;
        }
      }
      .horizontal-layoutItem-2 {
        .dropdown-container {
          width: 180px;
          z-index: 100;
        }
      }
    }
    .vertical-layoutItem-item {
      &.horizontal-layoutItem-0,
      &.horizontal-layoutItem-1,
      &.horizontal-layoutItem-2 {
        margin-top: 5px; // Adjust this value as needed to align with the label in horizontal-layoutItem-3
      }
      &.horizontal-layoutItem-3 {
        .MuiFormControl-root {
          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border-color: $light-blue;
              border-radius: 15px;
            }

            .MuiInputBase-input {
              color: $primary-color;

              &::placeholder {
                color: $primary-color;
                opacity: 1;
              }
            }

            .MuiInputAdornment-root {
              .MuiButtonBase-root {
                .MuiSvgIcon-root {
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      &.horizontal-layoutItem-4 {
        .MuiFormControl-root {
          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border-color: $light-blue;
              border-radius: 15px;
            }

            .MuiInputBase-input {
              color: $primary-color;

              &::placeholder {
                color: $primary-color;
                opacity: 1;
              }
            }

            .MuiInputAdornment-root {
              .MuiButtonBase-root {
                .MuiSvgIcon-root {
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
      float: left;
      width: 130px;
      margin-right: 10px;
      .multiple-select-wrap {
        width: 100%;
        .dropdown-wrapper {
          min-width: 8em;
        }
        .dropdown-title {
          width: 100%;
          margin-bottom: 2px;
          .dropdown-display-text {
            display: none;
          }
          span.icon {
            display: none;
          }
        }
        .dropdown-container {
          border-color: $light-blue;
          .dropdown-list {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            max-height: none;
            .list-item {
              width: 100%;
            }
            tbody {
              width: 100%;
              display: block;
              .\$spacing-p01 {
                padding-bottom: 10px;
                margin-bottom: 5px;
              }
              div {
                .badge {
                  background: $light-blue;
                }
              }
            }
            a {
              color: $dark-gray;
            }
          }
        }
        .dropdown-input-row {
          width: 100%;
          .icon-container {
            .icon {
              color: $primary-color;
            }
            .badge {
              background: $light-blue;
            }
          }
          .dropdown-parent {
            border-radius: 15px;
            &:-moz-placeholder {
              color: $primary-color;
              opacity: 1;
            }
            &::-webkit-input-placeholder {
              color: $primary-color;
              opacity: 1;
            }
            width: 100%;
            border-color: $light-blue;
            color: $primary-color;
          }
        }
      }
    }
  }
  .filter-button-group {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  .input-search {
    background-color: $white;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    width: 100%;
    input {
      font-size: 0.9rem;
    }
  }
}

.campaign-list {
  .filter-container
    .filter-container-popover
    .vertical-layoutItem-item.horizontal-layoutItem-1 {
    width: 170px;

    .dropdown-input-row .icon-container .icon {
      margin-right: 0px;
    }
  }
}

@media (max-width: 1200px) {
  .filter-container .filter-container-search {
    flex-direction: column;
  }
}
