.filter-container {
  .filter-button {
    color: "#005CBC";
    margin-top: 8px;
    gap: 5px;
  }
}

.inline-filter-container {
  .nonDebounced-filters {
    .horizontal-layout {
      margin-bottom: 0;
    }
  }
}
.all-filter-drawer {
  .content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  width: 30%;
  padding: 0;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .all-filter-header {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .filter-sub-header {
    align-items: center;
    display: flex;
    gap: 5px;
    font-weight: 400;
    padding-bottom: 1rem;
  }

  .content {
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;

    .member-info-box {
      background-color: #eff5fc;
      padding: 16px;
      border-radius: 8px;
    }

    .tags-container {
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 2rem;
      gap: 0.5rem;
      .tag {
        color: white;
        background: rgba(0, 120, 167, 1);
      }
    }

    .multiple-select-wrap .label-element {
      visibility: visible;
    }
  }

  .footer {
    display: flex;
    justify-content: end;
    padding: 16px;
    box-shadow: 0 -4px 6px rgba(0, 92, 188, 0.12);
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.badge-count {
  font-weight: 600;
  color: white;
  background: rgba(0, 120, 167, 1);
  border-radius: 20px;
}
