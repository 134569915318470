@import "../../styles/variables.scss";

.performance-detail-page-container {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.performances-page-content {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  gap: 16px;
  .performances-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    button:hover {
      background-color: #004ba0;
    }
  }
  .total-results-count {
    color: #616161;
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.performances-filter-styles {
  gap: 8px;

  .button {
    padding: 4px 8px;
    margin: 0;
    white-space: nowrap;
  }

  .debounced-filters {
    display: flex;
    align-items: center;
  }

  .nonDebounced-filters {
    .horizontal-layoutItem-0 {
      flex: 10 2;
    }

    .horizontal-layoutItem-1 {
      flex: 3 2;
    }

    .range-filter {
      width: max-content;
      margin-top: 12px;
    }
  }
  .nonDebounced-filters .horizontal-layoutItem-0 .MuiSelect-select > span,
  .nonDebounced-filters .horizontal-layoutItem-1 .MuiSelect-select > span {
    color: #1976d2 !important;
  }
  .horizontal-layoutItem-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-item {
    margin-top: 1rem;
  }

  .dropdown-container {
    height: 230px;
    z-index: 100;

    border-color: $light-blue;

    .dropdown-list {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      max-height: none;

      .list-item {
        width: 100%;
      }

      tbody {
        width: 100%;
        display: block;

        .\$spacing-p01 {
          padding-bottom: 10px;
          margin-bottom: 5px;
        }

        div {
          .badge {
            background: $light-blue;
          }
        }
      }

      a {
        color: $dark-gray;
      }
    }
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: $light-blue;
        border-radius: 15px;
      }

      .MuiInputBase-input {
        color: $primary-color;

        &::placeholder {
          color: $primary-color;
          opacity: 1;
        }
      }

      .MuiInputAdornment-root {
        .MuiButtonBase-root {
          .MuiSvgIcon-root {
            color: $primary-color;
          }
        }
      }
    }
  }

  .multiple-select-wrap {
    width: 100%;

    .dropdown-title {
      width: 100%;
      margin-bottom: 2px;

      .dropdown-display-text {
        display: none;
      }

      span.icon {
        display: none;
      }
    }

    .dropdown-input-row {
      width: 180px;

      .icon-container {
        .icon {
          color: $primary-color;
        }

        .badge {
          background: $light-blue;
        }
      }

      .dropdown-parent {
        border-radius: 15px;

        &:-moz-placeholder {
          color: $primary-color;
          opacity: 1;
        }

        &::-webkit-input-placeholder {
          color: $primary-color;
          opacity: 1;
        }

        width: 100%;
        border-color: $light-blue;
        color: $primary-color;
      }
    }
  }
}

.reciept-scan-performances-filter-styles {
  gap: 8px;

  .button {
    padding: 4px 8px;
    margin: 0;
    white-space: nowrap;
  }

  .debounced-filters {
    display: flex;
    align-items: center;
  }

  .nonDebounced-filters {
    .horizontal-layoutItem-0 {
      flex: 5 2;
    }

    .horizontal-layoutItem-1 {
      flex: 3 2;
    }
    .horizontal-layoutItem-2 {
      flex: 5 2;
    }

    .range-filter {
      width: max-content;
      margin-top: 12px;
    }
  }
  .nonDebounced-filters .horizontal-layoutItem-0 .MuiSelect-select > span,
  .nonDebounced-filters .horizontal-layoutItem-1 .MuiSelect-select > span {
    color: #1976d2 !important;
  }
  .horizontal-layoutItem-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-item {
    margin-top: 1rem;
  }

  .dropdown-container {
    height: 230px;
    z-index: 100;

    border-color: $light-blue;

    .dropdown-list {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      max-height: none;

      .list-item {
        width: 100%;
      }

      tbody {
        width: 100%;
        display: block;

        .\$spacing-p01 {
          padding-bottom: 10px;
          margin-bottom: 5px;
        }

        div {
          .badge {
            background: $light-blue;
          }
        }
      }

      a {
        color: $dark-gray;
      }
    }
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: $light-blue;
        border-radius: 15px;
      }

      .MuiInputBase-input {
        color: $primary-color;

        &::placeholder {
          color: $primary-color;
          opacity: 1;
        }
      }

      .MuiInputAdornment-root {
        .MuiButtonBase-root {
          .MuiSvgIcon-root {
            color: $primary-color;
          }
        }
      }
    }
  }

  .multiple-select-wrap {
    width: 100%;

    .dropdown-title {
      width: 100%;
      margin-bottom: 2px;

      .dropdown-display-text {
        display: none;
      }

      span.icon {
        display: none;
      }
    }

    .dropdown-input-row {
      width: 180px;

      .icon-container {
        .icon {
          color: $primary-color;
        }

        .badge {
          background: $light-blue;
        }
      }

      .dropdown-parent {
        border-radius: 15px;

        &:-moz-placeholder {
          color: $primary-color;
          opacity: 1;
        }

        &::-webkit-input-placeholder {
          color: $primary-color;
          opacity: 1;
        }

        width: 100%;
        border-color: $light-blue;
        color: $primary-color;
      }
    }
  }
}
.performances-listing-toast {
  z-index: 1;
}
