.userparticipation-content {
  display: flex;
  flex-direction: column;
  background-color: rgb(239, 245, 252);
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 0.25rem;
  .column-item {
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0.5rem;
    gap: 1rem;
    border: 1px solid rgb(215, 229, 248);
    &.column-added {
      background-color: rgb(251, 252, 254);
    }
    .added-column {
      width: 100%;
      .column-content {
        gap: 1rem;
      }
    }
    .button-group button {
      justify-content: center;
      width: 2rem;
      height: 2rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
    }
    .button-group-small {
      height: fit-content;
    }
  }
}
.content-modal {
  .modal {
    max-width: 90px;
    max-height: 80%;
    padding: 1rem;
  }
}

.campaign-column-content-modal {
  .modal {
    position: fixed;
    width: 75%;
    padding: 1.5rem;
    max-height: 87%;
    min-height: 90%;
    max-height: 92%;
    div {
      width: 100%;
    }
    .dropdown-input-row > div {
      width: unset;
    }

    .title {
      font-size: 1.125rem;
    }
    .dropdown-input-row {
      position: relative;
    }
    .required-fields {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-weight: 700;
      font-size: 0.75rem;
      margin-bottom: 1rem;
    }
    .input-wrapper {
      input {
        margin-right: 0;
        width: 100%;
      }
      .clear-button {
        display: none;
      }
    }
    .input-header .required-text {
      margin-right: 0;
    }
    .text-area .input-header .label-text .icon {
      display: none;
    }
    .multiple-select-wrap {
      .icon-container {
        position: absolute;
        gap: 0.1rem;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
      }
    }
  }
}

.modal-styles .modal {
  width: auto;
  max-width: 80%;
  min-width: 75%;
  padding: 10px 10px 12px 10px;
  overflow-y: auto;
}

.activity-setup-modal-paper {
  min-width: 75% !important;
  border: 10px solid var(--Colors-Blue-500, #d7e5f8);
  background: var(--layer-color-layer-01, #fbfcfe);
  min-height: 70% !important;
  max-width: 950px !important;
  .MuiDialogContent-root {
    padding: 1rem;
  }

  .bottom-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 1rem;
    gap: 0.5rem;
  }

  padding-top: 5px;
}

.view-modal-styles .modal {
  width: auto;
  max-width: 80%;
  min-width: 70%;
  padding: 10px 10px 12px 10px;
  overflow-y: auto;
  .field-label {
    font-size: 12px;
  }
  .field-value {
    font-size: 12px;
  }
}

.modal-background .modal {
  background-color: #fbfcfe;
}

.modal-styles .modal .title {
  font-size: 18px;
}

.rule-selector .dropdown-title span {
  font-weight: 600;
}

.custom-width {
  width: 50%;
}

.receipt-recency-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 23.8px;
  text-align: left;
  margin-bottom: 1rem;
}

.modalV2-toast-notification {
  right: 40% !important;
  top: 0% !important;
  width: 400px;
}

.language-input-container {
  width: 40%;
  min-width: 300px;
}
