// Import Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./_variables";
@import "./_utilities";
@import "./_colors";
@import "./_loader";
@import "./common.scss";
@import "./peacock-overrides.scss";
@import "./peacock-tokens.scss";

// Reset SCSS
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

// Optional: Set base font and color
body {
  font-size: 1rem;
  /* 16px */
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Inter", sans-serif;
  /* Use Inter font as default */
  //   color: #333;
}

// Global Styles
body {
  // Example background color and font
  background-color: $bg-body-color-light;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

p {
  font-size: 16px;
  line-height: 1.5;
  /* Set line height for paragraphs */
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.text-area-editor {
  ul {
    margin: 1em 2em !important;
    list-style: disc !important;
  }
  ol {
    margin: 1em 2em !important;
    list-style: decimal !important;
  }
}

li {
  margin-bottom: 5px;
  /* Add margin between list items */
}

blockquote {
  font-style: italic;
  margin: 0;
  padding: 10px;
  border-left: 3px solid #ccc;
  /* Add a border to the left */
}

/* Add more styles for other HTML elements as needed */

/** Navbar */
.header {
  height: $header-height;
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: #272727;
    color: $color-text-inverse;
    height: 100%;
    width: 100%;

    .pepsicoFullLogo {
      img {
        width: 100px;
      }
    }

    .dropdown-menu {
      position: relative;
      display: inline-block;
      cursor: pointer;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-menu-content {
      display: none;
      position: absolute;
      width: 100%;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      span {
        display: block;
      }
    }

    /* Show the dropdown menu on hover */
    .dropdown-menu:hover .dropdown-menu-content {
      display: flex;
    }
  }
}

.file-upload-drag-drop {
  width: 100% !important;
}
