.match-keywords-component-filter-container {
  .checkbox {
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
    .label-element {
      margin: 0px;
    }
  }
}
