.single-border-card {
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #aad2e2;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
}

.view-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 16px;
  width: 100%;
}

.modal-container.rewardGroupModal {
  width: 100%;

  .overlay {
    z-index: 999;
  }

  .modal {
    width: 98%;
    height: 90%;
    overflow-y: scroll;
    z-index: 1000;
  }
}

.modal-container.common-zIndex {
  .overlay {
    z-index: 999;
  }
  .modal {
    z-index: 1000;
  }
}

.selected-reward-accordion {
  border: none;
  box-shadow: none !important;
  background-color: #b3dacb !important;
  border-radius: 8px !important;
  padding: 8px 4px;
  margin: 16px 0px;

  .MuiAccordionSummary-root {
    padding: 0;
    min-height: auto;

    &.Mui-expanded {
      min-height: auto;
    }
  }

  .MuiAccordionSummary-content {
    font-size: 12px;
    font-weight: bold;
    margin: 0;

    &.Mui-expanded {
      margin: 0;
    }
  }
}

.rewardGroupSelectModal {
  .modal {
    width: 80%;
  }
}

.text-input {
  max-width: unset;
}

.text-input .input-wrapper {
  margin-bottom: 0px;
  input {
    width: 100%;
  }
}

.text-input.disabled .input-wrapper {
  margin-bottom: 0px;
}

.file-upload {
  width: 80%;
  max-width: 100%;
}

.rewardGroup-modal .modal,
.manageEntries-modal .modal,
.createProduct-modal .modal,
.createCodeGroups-modal .modal,
.rewardGroup-modal .MuiPaper-root,
.createCodeGroups-modal .MuiPaper-root,
.createProduct-modal .MuiPaper-root,
.manageEntries-modal .MuiPaper-root {
  &.MuiAccordion-root {
    min-width: auto !important;
    border: none;
    background: #fff;
  }
  border: 10px solid var(--Colors-Blue-500, #d7e5f8);
  background: var(--layer-color-layer-01, #fbfcfe);

  .bottom-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 30px;
    position: sticky;
    background-color: #fff;
    padding: 8px 0;
    bottom: 0;
    left: 0;
  }

  min-width: 95% !important;
  padding-top: 5px;
}

.manageEntries-modal .MuiPaper-root {
  min-height: 90%;
}

.manage-entries {
  .multiple-select-wrap {
    .dropdown-input-row {
      width: 100%;
      position: relative;
      color: #005cbc;
    }
    input {
      border-radius: 15px;
      width: 100%;
      border-color: #d7e5f8;
      color: #005cbc;
      background: #f7fafe;
    }
    // .dropdown-container {
    //   border-color: #d7e5f8;
    //   height: 150px;
    //   z-index: 1;
    //   .dropdown-list {
    //     width: calc(100% - 20px);
    //     height: calc(100% - 20px);
    //     max-height: none;
    //     tbody {
    //       width: 100%;
    //       display: block;
    //     }
    //     // .list-item {
    //     //   width: 100%;
    //     //   a {
    //     //     color: #616161;
    //     //   }
    //     // }
    //   }
    // }
  }
  .entries-form {
    .entries-switcher {
      margin-bottom: 16px;
      width: 100%;
      button {
        width: 50%;
      }
    }

    .checkbox {
      input {
        margin-right: 8px;
      }
    }

    .text-input {
      input {
        width: 100%;
      }
    }

    .dropdown-input-row {
      width: 100%;
      position: relative;
      input {
        width: 100%;
      }
    }
  }
}

.member-detail-side-panel {
  .sidepanel {
    .image-container {
      height: 0px;
    }
    .content-container {
      row-gap: 10px;
      display: flex;
      flex-direction: column;
    }
    width: 40%;
    padding: 0;
    .header {
      background-color: #eff5fc;
      flex-direction: row-reverse;
    }
    .content {
      overflow-y: scroll;
      padding: 16px;

      .member-info-box {
        background-color: #eff5fc;
        padding: 16px;
        border-radius: 8px;
      }
    }
    .sidepanel-footer {
      display: none;
    }
    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.card .card-content {
  word-break: break-word;
}
/* status tag colors */
.common-status-wrapper {
  display: flex;
  justify-content: space-between;

  .common-status {
    padding: 4px 8px;
    border-radius: 16px;
    color: #3a3a3a;
    font-weight: 400;
    font-size: 10px;
  }
  .draft {
    background-color: #616161;
    color: white;
  }
  .active {
    background-color: green;
    color: white;
  }
  .inactive {
    background-color: #dfdfdf;
  }
  .queued {
    background-color: rgb(255, 204, 73);
  }
  .generated {
    background-color: #9af39a;
  }
  .in_progress {
    background-color: rgb(246, 144, 35);
  }
  .published {
    background-color: rgb(255, 204, 73);
  }
  .completed {
    background-color: rgb(179, 218, 203);
  }
  .expired {
    background-color: rgba(246, 204, 210, 1);
  }
}

/* Common Classes for Popup Modal */

.grid-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .grid-wrap-item {
    &.grid-wrap-item-3 {
      width: 25%;
    }
    &.grid-wrap-item-4 {
      width: 33.3%;
    }
    &.grid-wrap-item-6 {
      width: 50%;
      /* &.w62{
              width: 100%;
              padding-right: 2%;
          } */
    }
    &.grid-wrap-item-8 {
      width: 66.6%;
    }
    &.grid-wrap-item-12 {
      width: 100%;
    }
  }
}

/* Most Common Classes */
@for $v from 10 through 100 {
  /* Margins */
  .m-#{$v} {
    margin: #{$v}px;
  }
  .mt-#{$v} {
    margin-top: #{$v}px;
  }
  .mr-#{$v} {
    margin-right: #{$v}px;
  }
  .mb-#{$v} {
    margin-bottom: #{$v}px;
  }
  .ml-#{$v} {
    margin-left: #{$v}px;
  }
  /* Padding */
  .p-#{$v} {
    padding: #{$v}px;
  }
  .pt-#{$v} {
    padding-top: #{$v}px;
  }
  .pr-#{$v} {
    padding-right: #{$v}px;
  }
  .pb-#{$v} {
    padding-bottom: #{$v}px;
  }
  .pl-#{$v} {
    padding-left: #{$v}px;
  }
  /* Font size */
  .fs-#{$v} {
    font-size: #{$v}px;
  }
  /* Border Radius */
  .fs-#{$v} {
    border-radius: #{$v}px;
  }
}

/* Text Align */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.bold {
  font-weight: bold;
}
.common-fields-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}

.common-field-pair {
  display: flex;
  gap: 8px;
}

.common-field-label {
  font-weight: bold;
  font-size: 10px;
}

.common-field-value {
  color: #666;
  font-size: 10px;
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

/* most common shadow class for all the screens */
.shadow {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.word-break {
  word-break: break-word;
}
.disabled-control #cancel-selection {
  display: none;
}

.data-filter {
  .MuiOutlinedInput-notchedOutline {
    border-color: #d7e5f8;
    border-radius: 15px;
  }
}

.disabled-div {
  .customDatePickerStyles {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
    color: #a0a0a0;
  }
}
