@import "../../styles";

.campaign-list-table-wrapper {
  table {
    width: 100%;
    border-spacing: 0;
    thead {
      th {
        text-transform: uppercase;
        text-align: left;
        padding: $spacing-03 $spacing-03;
        background: $color-background-layer-01;
        box-shadow: 0px -2px 0px 0px $color-border-default inset;
        margin-bottom: $spacing-03;
        font-size: 11px;
        color: $color-text-secondary;
      }
    }
    tbody {
      box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
      background: $color-background-layer-01;
      font-size: 12px;
      tr {
        td {
          color: $color-text-secondary;
          background: $color-background-layer-01;
          box-shadow: 0px -2px 0px 0px $color-border-default inset;
          padding: $spacing-03 $spacing-03;
          font-size: 12px;
          &.no-space-title {
            max-width: 190px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .status {
    padding: $spacing-02 $spacing-03;
    border-radius: 6px;
    font-size: 11px;
    font-weight: normal;
    color: $color-text-primary;
  }

  .status.active {
    background-color: $color-green-600;
    color: #fff;
  }

  .status.inactive {
    background-color: $color-gray-100;
  }

  .status.draft {
    background-color: $color-gray-500;
    color: #fff;
  }
  .status.published {
    background-color: $color-yellow-400;
  }
  .status.completed {
    background-color: #667e88;
    color: #fff;
  }
}
