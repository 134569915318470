@import "../../../styles";

.add-leaderboard-select-wrap {
  .select-count-box {
    background-color: #f3f7fd;
    padding: 8px;
  }

  .leaderboards-tags {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-02;
    .badge {
      background-color: #b3dacb;
      height: fit-content;
      color: #000;
    }
  }

  .leaderboards-list-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    thead {
      th {
        text-align: left;
        padding: 14px 8px;
        background: #fbfcfe;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        margin-bottom: 8px;
        color: #616161;
        font-size: 0.8rem;
        text-transform: uppercase;
        &.th-status {
          min-width: 170px;
        }
        &.th-actions {
          min-width: 260px;
        }
      }
    }
    tbody {
      box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
      background: #fbfcfe;
      td {
        background: #fbfcfe;
        box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
        padding: 18px 8px;
        color: #616161;
        font-size: 0.8rem;
      }
    }
    .leaderboards-table-cell-status {
      height: 2rem;
      gap: 8px;
      .edit-winners-button button {
        justify-content: center;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        border-radius: 0.25rem;
      }
      .button-group-small {
        height: fit-content;
      }
    }
  }
}
