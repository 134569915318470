@import "../../../styles/variables";

div[role="dialog"] {
  z-index: 3334;
}

.performances-container {
  .performances-list-container {
    .performances-list-table {
      width: 100%;
      border-spacing: 0;
      border-collapse: separate;

      thead {
        th {
          text-align: left;
          padding: 14px 8px;
          background: $background-light-white; // from your variables
          box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
          margin-bottom: 8px;
          color: $dark-gray;
          font-size: 0.8rem;
          text-transform: uppercase;

          &.performances-column-status {
            width: 10%;
          }
        }
      }

      tbody {
        box-shadow: 0px 0px 24px 0px rgba(0, 92, 188, 0.08);
        background: $background-light-white;

        td {
          background: $background-light-white;
          box-shadow: 0px -2px 0px 0px rgba(215, 229, 248, 1) inset;
          padding: 18px 8px;
          color: $dark-gray;
          font-size: 0.8rem;

          &.performances-widget-status-type {
            justify-content: space-between;
            margin-bottom: 8px;
          }

          .performances-lifecycle-status {
            padding: 4px 8px;
            border-radius: 5px;
            font-weight: normal;
            font-size: 0.8em;
            display: inline-block;

            &.approved {
              background-color: rgba(179, 218, 203, 1);
              color: #000;
            }
            &.pending {
              background-color: rgba(215, 229, 248, 1);
              color: #000;
            }
            &.rejected {
              background-color: rgba(242, 179, 188, 1);
              color: #000;
            }
            &.default {
              background-color: #eee;
              color: #000;
            }
          }
        }
      }
    }
  }
}
