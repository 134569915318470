.pefornace-confirmation-modal {
  width: 300px;
}

.modal-container {
  .modal {
    padding: 30px;
    div {
      span {
        &.title {
          width: 100%;
        }
      }
    }
  }
}
