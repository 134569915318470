.performance-details-container {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.performance-details-section-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .performance-details-section-card-header-right {
    display: flex;
    flex-direction: row;
    column-gap: 5px;

    gap: 10px;
  }
}

.performance-detail-card {
  padding: 20px;
  background-color: var(--blue500);
}

.performance-details-status-tag {
  background-color: rgba(0, 120, 167, 1);
  color: #fff;
}

.performance-details-container-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  column-gap: 5px;
}

.performance-details-drawer {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 400px;
}

.performance-details-drawer-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 12px;

  .performance-details-drawer-footer-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .performance-details-drawer-footer-description {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.7);
  }
}
