// JsonViewer.module.scss

.json-viewer-container {
  position: relative;
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

.preformatted-text {
  background: #282c34;
  color: #88efc6;
  padding: 15px;
  border-radius: 8px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
}

.code-preview {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  overflow: auto;
}

.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

pre {
  margin: 0;
}

code {
  font-family: "Courier New", Courier, monospace;
}

.json-key {
  color: #9ad464;
}

.json-string {
  color: #ff9e64;
}

.json-number {
  color: #52fd72;
}

.json-boolean {
  color: #89caff;
}

.json-null {
  color: #c678dd;
}
