/* ImagePreview.css */
.image-preview {
  position: relative;
  display: inline-block;
  background-color: white;
  width: 100%;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}

/* Added overflow-x: auto to enable horizontal scrolling when zoomed in */
.image-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #616161;
  overflow-x: auto;
  overflow-y: auto;
}

/* Added styles for zoomed image to ensure proper scrolling */
.image-container img {
  transform: scale(var(--zoom, 1));
  transform-origin: center center;
  will-change: transform; /* Hint the browser to optimize for transformations */
  transition: transform 0.2s ease-in-out; /* Smooth transition for zooming */
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
}

.image-preview-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.controls-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 4001;
  background-color: rgba(215, 229, 248, 1);
  padding: 16px;
}

.info-full-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 4001;
  background-color: rgba(215, 229, 248, 1);
  padding: 16px;
}

.image-preview.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4000;
}

/* Added styles for zoomed image in full-screen mode */
.image-preview.full-screen .image-container {
  overflow-x: auto;
  overflow-y: auto;
}

.controls {
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 4001;
  padding: 16px;
}

.info {
  font-size: small;
  padding: 10px;

  bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: black;
  z-index: 4001;
}

.file-name {
  text-align: left;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #474747;
}

.file-name:hover {
  overflow: visible;
  white-space: normal;
  background-color: #d7e5f8;
  z-index: 1002;
  cursor: pointer;
  cursor: pointer;
}

.file-size {
  text-align: right;
  color: #474747;
  .file-value {
    color: #000000;
    font-weight: 500;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #333;
}

@media (max-width: 768px) {
  .image-preview {
    width: 100%;
    height: auto;
  }

  .controls {
    flex-direction: column;
    align-items: center;
  }

  .controls-left,
  .controls-right {
    padding: 5px;
  }

  .info {
    flex-direction: column;
    align-items: center;
    padding: 5px;
  }

  .file-name,
  .file-size {
    max-width: 100%;
    text-align: center;
  }
}
