.loyalty-leaderboardspage {
  .leaderboards-container {
    .container-card {
      align-items: center;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 -2px 0 0 rgba(215, 229, 248, 1) inset;
      height: calc(100% - 19px);
    }

    .page-container {
      align-items: center;
      width: 100%;
      background: rgba(255, 255, 255, 1);
    }

    .page-content {
      display: flex;
      flex-direction: column;
      padding: 16px 16px 0 16px;
      gap: 16px;
      &.contained {
        padding: 16px;
      }
      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(91.5vh - 138px);
        padding-bottom: calc(8.5vh + 1rem);
      }
      .main-content {
        overflow-y: auto;
        // max-height: calc(91.5vh - 138px);
      }
      .no-records {
        display: flex;
        align-items: center;
        width: 100%;
        height: 200px;
        justify-content: center;
        font-weight: 700;
        color: grey;
        font-size: 20px;
      }
    }

    .search-filter-container {
      display: flex;
      gap: 16px;
      width: 100%;
    }

    .input-search {
      max-width: 210px;
      border-top: 0;
      border-bottom: 1px solid #3a3a3a;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      &:focus {
        border-top: 0;
        border-bottom: 1px solid #005cbc;
        border-right: 0;
        border-left: 0;
      }
      &:focus-within {
        border-top: 0;
        border-bottom: 1px solid #005cbc;
        border-right: 0;
        border-left: 0;
      }
      &:active {
        border-top: 0;
        border-bottom: 1px solid #005cbc;
        border-right: 0;
        border-left: 0;
      }
      input {
        &:focus {
          &::placeholder {
            color: #005cbc;
          }
        }
        font-size: 14px;
      }
    }
    .grid-container {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      height: calc(91.5vh - 138px);
      grid-auto-rows: min-content;
    }

    .leaderboard-detail-card {
      padding: 16px;
      width: 100%;
      background: rgba(255, 255, 255, 1);
      .campaign-details {
        background-color: #eff5fc;
      }
    }
  }
}

.create-leaderboards-modal .modal,
.create-leaderboards-modal .MuiPaper-root {
  &.MuiAccordion-root {
    min-width: auto !important;
    border: none;
    background: #fff;
  }
  border: 10px solid var(--Colors-Blue-500, #d7e5f8);
  background: var(--layer-color-layer-01, #fbfcfe);

  min-width: 60% !important;
  max-width: 75%;
  padding-top: 5px;

  .horizontal-layoutItem-item .dropdown-wrapper {
    width: 100%;
    max-width: 100%;
    .label-element {
      width: 100%;
    }
    .dropdown-input-row {
      width: 100%;
    }
  }
}
