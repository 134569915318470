//variables
@import "../../../styles/variables";

.search-filter-leaderboard-container {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  .clear-all-btn {
    display: inline-block;
    font-size: 12px;
    color: $primary-color;
    margin-top: 10px;
    margin-left: 5px;
    width: 70px;
    cursor: pointer;
  }
  .search-wrapper {
    display: inline-block;
    width: 215px;
    .input-search {
      background: $white;
      font-family: "Inter", sans-serif;
    }
  }
  .filter-wrapper {
    width: 75%;
    margin-left: 10px;
    display: inline-block;
    .filter-dropdown {
      display: inline-block;
      position: relative;
      width: 140px;
      margin-right: 10px;
      &.approval-method {
        width: 160px;
      }
      .dropdown-input-row {
        width: 100%;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
        display: inline-block;
      }
      .dropdown-container {
        z-index: 999;
        width: 260px;
        height: 220px;
        margin-top: 2px;
        border-color: $light-blue;
        .dropdown-list {
          width: 100%;
          display: contents;
          tbody {
            width: 100%;
            display: block;
          }
          .list-item {
            width: 100%;
          }
          .heading-content {
            color: $dark-gray;
            padding-left: 5px;
            margin-bottom: 3px;
          }
          td {
            padding: 5px 10px;
            a {
              color: $dark-gray;
            }
          }
        }
      }
      .dropdown-container {
        .dropdown-list {
          .list-item {
            width: 100%;
          }
          tbody {
            width: 100%;
            display: block;
            .\$spacing-p01 {
              padding-bottom: 10px;
              margin-bottom: 5px;
            }
            div {
              .badge {
                background: $light-blue;
              }
            }
          }
          a {
            color: $dark-gray;
          }
        }
      }
      .dropdown-input-row {
        width: 100%;
        border: 1.5px solid$light-blue;
        border-radius: 25px;
        background-color: #fbfcfe;
        .icon-container {
          .icon {
            color: $primary-color;
          }
          .badge {
            background: $light-blue;
          }
        }
        .dropdown-parent {
          border-radius: 15px;
          margin-left: 1px;
          &:-moz-placeholder {
            color: $primary-color;
            opacity: 1;
          }
          &::-webkit-input-placeholder {
            color: $primary-color;
            opacity: 1;
          }
          border-color: $light-blue;
          color: $primary-color;
        }
      }
      &.dropdown-segment {
        .dropdown-container {
          height: 130px;
        }
      }
      &.dropdown-category {
        .dropdown-container {
          width: 300px;
          height: 250px;
        }
      }
    }
    .date-picker-material-ui-wrap {
      display: inline-flex;
      width: 131px;
      margin-right: 10px;
      .customDatePickerStyles input {
        padding: 8px 6px;
        color: $dark-gray;
      }
    }
  }
}
